<template>
  <div class="content-body">
    <div class="no-print-on-slip">
      <div class="row mt-2 mg-sm-0 no-print print-header-mt">
        <div class="col-6">
          <div class="content-header pd-l-0">
            <div>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'new-sales' }">Sales</router-link>
                  </li>
                  <li
                    class="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Manage Sales
                  </li>
                </ol>
              </nav>
              <h4 class="content-title content-title-xs">Manage Sales</h4>
            </div>
          </div>
          <!-- content-header -->
        </div>
        <!-- col -->
        <div class="col-6 tx-right">
          <router-link
            v-if="checkSinglePermission('create_sale')"
            :to="{ name: 'new-sales' }"
            class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
            tag="button"
            >New Sales</router-link
          >
        </div>
        <!-- col -->
      </div>
      <!-- row -->
      <div class="bg-theam-secondary table-search-head no-print pt-0">
        <div class="row">
          <div class="filter-input col-md-9 d-block mr-0 pr-0">
            <select
              v-model="filterParams.pageIndex"
              @change="selectTotalData"
              class="mg-t-5 mr-2"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value>All</option>
            </select>
            <select
              v-model="filterParams.branch"
              class="filter-input mr-2 mg-t-5"
              @change="selectBranch"
            >
              <option value>All Branch</option>
              <option
                v-for="(branch, index) in slipSettings.branches"
                :key="index"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
            <select
              v-model="filterParams.sales_type"
              class="filter-input mr-2 mg-t-5"
              @change="selectSalesType"
            >
              <option value>All Sales</option>
              <option value="1">Sales Bills</option>
              <option value="2">Canceld Bills</option>
            </select>
            <SearchDayMonthComponent
              @clicked="monthDayClicked"
            ></SearchDayMonthComponent>
            <div class="d-inline-block mr-2">
              <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
              <input
                type="date"
                v-model="filterParams.from_date"
                placeholder="From"
                class="filter-input mr-1 mg-t-5"
                @input="selectFromDate"
              />
              <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
              <input
                type="date"
                v-model="filterParams.to_date"
                placeholder="To"
                class="filter-input mg-t-5 mr-1"
                @input="selectEndDate"
              />
            </div>
          </div>
          <div class="col-md-3 ml-0 pl-0 mg-t-5">
            <input
              type="text"
              v-model="filterParams.searched"
              class="float-right"
              placeholder="Search..."
              @keyup="searchedKeyword"
            />
          </div>
        </div>
      </div>
      <div class="row py-0">
        <div class="col-md-12 overflow-x-auto">
          <table
            class="table table2 table-hover margin-top-print"
            id="tableData"
          >
            <thead>
              <tr>
                <th class="wd-5p table-start-item">S.N.</th>
                <th class="wd-10p">Date</th>
                <th class="wd-10p">Bill No</th>
                <th
                  :class="
                    authData.general_setting.vatable == 1 ? 'wd-20' : 'wd-15'
                  "
                >
                  Customer Name
                </th>
                <th class="wd-10p">Sale Amt.</th>
                <th class="wd-5p" v-if="authData.general_setting.vatable == 1">
                  VAT
                </th>
                <th class="wd-10p">Bill Amt.</th>
                <th class="wd-10p">Paid Amt.</th>
                <th class="wd-10p">Payment mode</th>
                <th class="wd-5p">Due</th>
                <th
                  class="wd-10p text-center no-print"
                  v-if="checkIfAnyPermission(['view_sale', 'cancel_sale'])"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody v-if="!loading && dataSets.length > 0">
              <!-- <tr v-if="!removeList.includes(sales.id)"> -->
              <tr v-for="(sales, index) in dataSets" :key="index">
                <td scope="row" class="table-start-item">
                  {{ pageSerialNo + index }}
                </td>
                <td>{{ sales.date }}</td>
                <td>
                  {{ sales.prefix }}{{ sales.bill_no }}
                  <span class="tx-danger font-s" v-if="sales.status == 2"
                    >- Canceled</span
                  >
                </td>
                <td>{{ sales.customer.name }}</td>
                <td>{{ parseDigitForSlip(sales.sales_amount) }}</td>
                <td v-if="authData.general_setting.vatable == 1">
                  {{ parseDigitForSlip(sales.vat_amount) }}
                </td>
                <td>{{ parseDigitForSlip(sales.bill_amount) }}</td>
                <td>
                  {{ parseDigitForSlip(sales.paid_amount) }}
                </td>
                <td>
                  <span v-if="sales.paid_amount != 0">
                    <span v-if="sales.payment_methods">{{
                      sales.payment_methods.title
                    }}</span
                    >&nbsp;
                    <span v-if="sales.bank"
                      >&nbsp;-&nbsp;{{ sales.bank.bank }}</span
                    >
                  </span>
                  <span v-else> Credit </span>
                </td>
                <td>{{ parseDigitForSlip(sales.due_amount) }}</td>
                <td
                  class="text-center no-print"
                  v-if="checkIfAnyPermission(['view_sale', 'cancel_sale'])"
                >
                  <a
                    href="javascript:;"
                    class="mr-3"
                    v-if="checkSinglePermission('view_sale')"
                  >
                    <i
                      class="fas fa-eye tx-success"
                      title="View Bill"
                      @click="view('salesSlip', sales.id)"
                    ></i>
                  </a>
                  <a
                    href="javascript:;"
                    v-if="
                      sales.status != 2 && checkSinglePermission('view_sale')
                    "
                  >
                    <i
                      class="fa fa-edit mr-2"
                      title="Edit Sale"
                      @click="editSale(sales.id)"
                    ></i>
                  </a>
                  <a
                    href="javascript:;"
                    v-if="
                      sales.status != 2 && checkSinglePermission('cancel_sale')
                    "
                  >
                    <i
                      class="fa fa-times-circle tx-danger"
                      title="Cancel Sale"
                      @click="cancelSale(sales.id)"
                    ></i>
                  </a>
                </td>
              </tr>
              <!-- </tr> -->
            </tbody>
            <tbody v-else-if="error">
              <tr>
                <td colspan="11" style="text-align: center">
                  {{ errorMessage }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!loading && dataSets.length == 0">
              <tr>
                <td colspan="11" style="text-align: center">
                  No records found.
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="loading">
              <tr>
                <td colspan="11" style="text-align: center">Loading.....</td>
              </tr>
            </tbody>
          </table>
          <!-- <NewPagination @changePage="onChangePage"  /> -->
          <Paginate
            v-model="page"
            :pageCount="totalPageCount"
            :containerClass="'pagination'"
            :clickHandler="clickCallback"
            v-if="totalPageCount > 1"
          />
        </div>
      </div>
    </div>
    <SalesSlip @printSlip="printSlip" />
  </div>
</template>
<script>
import SalesSlip from "./SalesSlip";
import Services from "./Services/Service";
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import SearchDayMonthComponent from "../../../shared/SearchDayMonthComponent";
import _ from "lodash";

export default {
  components: {
    SalesSlip,
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      loading: true,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      errorMessage: "",
      page: 1,
      filterParams: {
        pageIndex: 10,
        branch: "",
        sales_type: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
      "authData",
    ]),

    ...mapGetters("sales", ["dataSets", "slipSettings"]),
  },
  beforeMount() {
    // get Data
    this.getFilteredData();
    this.pageReload = true;
    
    Services.ManageSales.getSlipPredefinedData()
      .then((response) => {
        // this.$store.commit('setDataLists9', response.data.data);
        this.$store.commit("sales/setSlipSettings", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted(){
    // var mainInput = document.getElementById("from-nepali");
    // var that=this;
    // mainInput.nepaliDatePicker({
    //   onChange: function(e){
    //     console.log(e);
    //      that.filterParams.from_date = e.bs;
    //      that.getFilteredDataWithoutDates();
    //      console.log(that.filterParams);
    //   }
    // });
  },
  methods: {
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    editSale(id) {
      this.$router.push(`/sales/edit/${id}`);
    },
    onChangePage(pageNo) {
      console.log(pageNo);
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectSalesType() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.$store.commit("sales/setItemSearchLoading", true);
      this.getFilteredDataWithoutDates();
    }, 500),

    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.ManageSales.getFilteredSales(params)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("sales/setDataSets", value.data);
          this.totalPageCount = value.total_no_of_pages;
          this.$store.commit("sales/setItemSearchLoading", false);
        })
        .catch((error) => {
          this.$store.commit("sales/setItemSearchLoading", true);
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("sales/setItemSearchLoading", false);
        });
    },

    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },

    printSlip(id) {
      //once the modal is opened popup print window
      window.print();

      window.onafterprint = this.afterPrint(id);
    },
    afterPrint(salesId) {
      Services.Sales.updatePrintBillCount({ "sales-id": salesId })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelSale(id) {
      this.$swal({
        title: "Do you really want to cancel this sales ?",
        html: `<label>Remarks: </label>
        <textarea id="textarea" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("textarea").value;
          // cancel the sales with remarks
          return Services.ManageSales.cancelSale(id, { remarks: remarks })
            .then((response) => {
              this.dataSets.forEach((item, index) => {
                if (item.id == id) {
                  this.dataSets[index]["status"] = 2;
                  this.dataSets[index]["remarks"] = remarks;
                  this.dataSets[index]["canceled_date"] = this.today;
                }
              });
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },

    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },

  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
